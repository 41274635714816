@import "~@flaticon/flaticon-uicons/css/all/all";


.switch{
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100px;
  &:hover {
    cursor: pointer;
    box-shadow: 2px 4px 4px 0px #00000030;
    scale: 1.1;
  }
}


.css-1b86cyw-MuiList-root{
  box-shadow:inset 1px 1px 1px 1px rgb(51, 51, 51);
  padding: 10px;
}

.draggable-item {
  user-select: none; /* Modern browsers */
  -webkit-user-drag: none; /* Prevent dragging on Safari */
  -webkit-touch-callout: none; /* Disable long-press callout on mobile */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.css-cfd5wn-MuiListItem-root{
  &:hover{
    font-size: large;
  }
}

.fi-br-refresh{
  cursor: pointer;
  align-self: center;
  cursor: pointer;
  margin-top: 5px;
  transition: all .25s ease-in-out;
}

.fi-br-refresh:hover{
      scale: 1.2;
      color: green;
      transform: rotateZ(45deg);
      transition: all .5s ease-in-out;
}

.css-4k4mmf-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled{
    pointer-events: inherit !important;
    cursor: not-allowed
}

.css-960umw-MuiCircularProgress-circle{
  stroke: orange !important
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;

  #mainContent{
    height: 100vh;
    width: 100vw;
    background-color: #282c34
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-slide > div > div, 
.slick-active > div > div, 
.slick-current > div > div{
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  max-height: 150px;
  scale: 0.70;
  p{
    color: #ff8800;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  #r3f{
    margin-top: 0px;
    margin-bottom: -0px;
  }
}

#landingSplash {
  h1{
    font-size: 70px;
    color: whitesmoke;
    position: absolute;
    left: 0px;
    z-index: 10;
  }
  h2{
    font-size: 70px;
    color: #ff8800;
    position: absolute;
    left: 0px;
    top: 500px;
  }
}

body {
  overflow: hidden
}

h3{
  margin-bottom: 5px
}

h2{
  margin-bottom: 5px;
  margin-top: 1px
}

  .dodecGeo {
    cursor: url('/public/assets/roll.ico'), auto
  }

  #titleName{
    font-size: 1.5em;
    text-wrap: nowrap;
    margin-left: 10px
  }

  #socials{
    text-wrap: nowrap;
  }

  .projects {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #ff8800;
    font-size: 30px;
  }

  @media screen and (min-width: 379px) and (max-width: 430px) {
    .projects {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      color: #ff8800;
      font-size: 30px;
      h3{
        font-size: 20px;
        margin-top: 10px
      }
      div{
        section{
          div{
            font-size: 20px !important;
            display: flex  !important;
            flex-direction: row  !important;
            flex-wrap: nowrap  !important;
            align-items: flex-start  !important;
            justify-content: flex-start  !important;
            h2{
              font-size: 20px !important;
              display: flex  !important;
              flex-direction: row  !important;
              flex-wrap: wrap  !important;
              align-items: flex-start  !important;
              justify-content: flex-start  !important;
            }
            p{
              font-size: 15px !important;
            }
          }
        }
      }
    }
    .carousel {
      width: 100vw;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      flex-direction: column;
      color: #ff8800;
      font-size: 20px;
      .slick-slide > div > div, 
      .slick-active > div > div, 
      .slick-current > div > div{
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
        max-height: 150px;
        scale: 0.40;
        p{
          color: #ff8800;
          font-family: "Bebas Neue", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 60px;
          margin-top: 20px;
          margin-bottom: 0px;
        }
        #r3f{
          margin-top: 0px;
          margin-bottom: -0px;
        }
      }
      .slick-dots, .slick-arrow{
        display: none !important;
      }
    }

    #titleName{
      font-size: 1.5em;
      text-wrap: nowrap;
      margin-left: 10px
    }
  
    #socials{
      text-wrap: nowrap;
      font-size: 15px;
    }

    img{
      display: none !important;
    }

    #sideBar{
      width: 40vw !important;
    }

    textarea{
      width: 96vw !important;
    }
  }

  .slick-slide img{
    display: block !important;
  }

  .nav-link{
    color: orange !important;
    margin-left: 10px
  }

  canvas{
    opacity: 0;
    animation: fadeInCanvas 2.5s ease-in 1s forwards;
  }

  @keyframes fadeInCanvas {
    0%{
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


